* {
    box-sizing: border-box;
}

/* nav bar */
/* .nav {
    background-color: rgb(255, 166, 0);
    color: black;
    display: flex;
    justify-content: space-between;  
    align-items: stretch;
    padding: 0rem;
    position: sticky;
} */

/* .nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    max-width: 1500px;
    margin: 0.5rem;
} */

.nav ul {
    margin: 0;
    list-style: none;
    justify-content: flex-end;
    align-items: center;
    display: flex;
    gap: 1.5rem;
    font-size: 1.25rem;
}

.nav-button {
    padding: 0.25rem 0.5rem 0.25rem 0.5rem;
}

.nav a {
    color: inherit;
    text-decoration: none;
    height: 100%;
    display: flex;
    align-items: center;
}

.nav li.active {
    background-color: rgba(218, 141, 0, 0.718);
}

.nav li:hover {
    background-color: rgb(197, 128, 0);
}

.pythonLogo {
    height: 2.6rem;
    width: 2.6rem;
    gap: 0rem;
    padding: 0rem;
    margin-top: 10px;
}

.site-title {
    font-size: 1.8rem;
    justify-self: start;
}
